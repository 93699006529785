<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'Using TLC, how would you know if the reaction is complete?'
            : 'En utilisant la CCM, comment sauriez-vous que la réaction est complète ?'
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab4Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'The limiting reagent spot no longer appears in the reaction mixture',
          value: 'LrSpotGone',
        },
        {
          text: 'There is a new spot showing in the reaction mixture lane, suggesting the formation of a new product',
          value: 'newSpotForProduct',
        },
        {
          text: 'All reagent spots no longer appear in the reaction mixture',
          value: 'allReagentSpotsGone',
        },
      ],
      optionsFr: [
        {
          text: "La tache du réactif n'apparaît plus dans le mélange réactionnel",
          value: 'LrSpotGone',
        },
        {
          text: "Il y a une nouvelle tache pour le mélange réactionnel, ce qui suggère la formation d'un nouvel produit",
          value: 'newSpotForProduct',
        },
        {
          text: "Toutes les taches de tous les réactifs n'apparaissent plus dans le mélange réactionnel",
          value: 'allReagentSpotsGone',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
